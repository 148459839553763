import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  InputAdornment,
  MenuItem,
  Stack,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "react-notifications/lib/notifications.css";
import {
  AdmissionDetailComponent,
  HouseComponent,
  TransportationComponent,
} from "../../components/ExistStudent/AllComponent";
import styled from "styled-components";
import { useQuery } from "react-query";
import {
  getClassDropdown,
  getStudentProfileByRequestDocId,
} from "app/services/student-management";
import {
  selectedMediumSelector,
  selectActiveSession,
} from "app/modules/schools/selectors";
import { selectSelectedSchoolId } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";

import moment from "moment/moment";
import { useParams } from "react-router-dom";
import PreviewForExistStudent from "./PreviewForExistStudent";
import { toast } from "react-toastify";
import { getFeeStructureForStudentRegistration } from "app/services/schoolService/newFee.service";
import FeeStructureInfo from "../../pages/CreateStudent/components/FeeStructureInfo";
import DocumentInfo from "../../pages/CreateStudent/components/DocumentInfo";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { PencilSquare } from "react-bootstrap-icons";
import EditFeeRelaxationDialog from "../../pages/CreateStudent/components/EditFeeRelaxationDialog";
import CustomLoader from "app/components/common/CustomLoader";
const initialState = {
  profilePic: "",
  AADHAR: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  DOB: "",
  category: "",
  community: "",
  gender: "",
  studentAdmissionType: "newStudent",
  disability: "",
  bloodGroup: "",
  currentAddress: {
    address: "",
    dist: "",
    pinCode: "",
    state: "",
  },
  permanentAddress: {
    address: "",
    dist: "",
    pinCode: "",
    state: "",
  },
  fatherInfo: {
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    occupation: "",
    AADHAR: "",
  },
  motherInfo: {
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    occupation: "",
    AADHAR: "",
  },
  guardianInfo: {
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    occupation: "",
    relation: "",
    AADHAR: "",
  },

  house: {
    houseName: "",
    houseColor: "",
    membershipRole: "",
  },
  admissionDate: new Date(),
  admissionType: "Regular",
  registrationNumber: null,
  admissionNumber: "",
  rollNumber: "",
  srNumber: "",
  classDocId: "",
  section: "",
  transportInfo: {
    using: "No",
    routeNumber: "",
    to: "",
    vehicleNumber: "",
    feeAmount: "",
    transportFeeDocId: "",
    remarks: "",
    routeName: "",
    feeCode: "",
  },
};
const NameComponent = ({ head, data }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Typography fontSize={14} color="primary" sx={{ opacity: 0.75 }}>
        {head}
      </Typography>
      <Typography fontSize={16} fontWeight={600} color="primary">
        {data && data !== "undefined" && data !== undefined
          ? String(data).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Box>
  );
};

const validationSchema = Yup.object().shape({
  admissionType: Yup.string().required("Admission type is required"),
  classDocId: Yup.string().required("Class Name is required"),
  section: Yup.string().required("Section is required"),
  transportInfo: Yup.object().shape({
    using: Yup.string().required("Select whether student is using transport"),
    feeAmount: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("Fee amount is required"),
    }),
    routeNumber: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("Route Number is required"),
      otherwise: Yup.string().nullable(),
    }),
    to: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("To required"),
    }),
    vehicleNumber: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("Vehicle number is required"),
    }),
    transportFeeDocId: Yup.string().when("transportInfo.using", {
      is: "Yes",
      then: Yup.string().required("Fee structure is required"),
    }),
    remarks: Yup.string().notRequired(),
  }),
});

const EditDetails = () => {
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);

  const [data, setData] = useState({});
  const { requestDocId } = useParams();
  const [showPreview, setShowPreview] = useState(false);
  const [expanded, setExpanded] = useState("all");

  const [selectedStop, setSelectedStop] = React.useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const selectedSchoolId = useSelector(selectSelectedSchoolId);

  const [state, setState] = useState({
    feeRelaxationCategoryDialog: false,
    feeRelaxationCategory: "General",
    certificateName: "",
    certificateUrl: "",
    staffWardSchoolName: "",
    employeeId: "",
    empoyeeName: "",
    siblingDetails: [
      {
        schoolDocId: "",
        studentDocId: "",
        classDocId: "",
      },
    ],
  });
  const [collectedDocumentList, setCollectedDocumentList] = useState({
    list: [
      {
        label: "Aadhar Card",
        checked: false,
      },
      {
        label: "Transfer Certificate",
        checked: false,
      },
      {
        label: "Character Certificate",
        checked: false,
      },
      {
        label: "Last Year Marksheet",
        checked: false,
      },
      {
        label: "Income Certificate",
        checked: false,
      },
      {
        label: "Caste Certificate",
        checked: false,
      },
      {
        label: "Residential Certificate",
        checked: false,
      },
    ],
    dialog: false,
  });
  const [feeData, setFeeData] = useState({
    list: [],
  });
  const handleClose = () => {
    setShowPreview(false);
  };

  const mainFormik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setShowPreview(true);
    },
  });
  const { isLoading: getStudentByRequestIdLoading } = useQuery({
    queryKey: ["getStudentByRequestId"],
    queryFn: () => getStudentProfileByRequestDocId(requestDocId),
    onSuccess: (value) => {
      if (!mainFormik.values?.classDocId) {
        mainFormik.setValues({
          ...value?.data?.obj,
          admissionDate: new Date(),
          admissionType: "Regular",
          studentAdmissionType: "newStudent",
          registrationNumber: "",
          rollNumber: "",
          classDocId: "",
          className: "",
          section: "",
          stream: "",
          transportInfo: {
            feeAmount: 0,
            remarks: "",
            routeNumber: "",
            to: "",
            using: "No",
            vehicleNumber: "",
            transportFeeDocId: "",
          },

          house: {
            houseName: "",
            houseColor: "",
            membershipRole: "",
          },
        });
      }
      setData(value?.data?.obj);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const { data: classesResponse, isLoading: getClassDropdownLoading } =
    useQuery({
      queryKey: ["getClassDropdown", selectedSchoolId, selectedMedium],
      queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    });
  const {
    isLoading: getAllFeeStructureLoading,
    isFetching: getAllFeeStructureFetching,
  } = useQuery({
    queryKey: [
      "getAllFeeStructure",
      selectedSession,
      mainFormik.values.section,
      state.feeRelaxationCategory,
      mainFormik.values.studentAdmissionType,
    ],
    queryFn: () =>
      getFeeStructureForStudentRegistration({
        medium: selectedMedium,
        session: selectedSession,
        classDocId: mainFormik.values.classDocId,
        section: mainFormik.values.section,
        feeRelaxationCategory: state?.feeRelaxationCategory,
        studentAdmissionType: mainFormik.values.studentAdmissionType,
      }),
    onSuccess: (success) => {
      setFeeData(success?.data);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: mainFormik.values.section ? true : false,
  });
  return (
    <>
      <CustomLoader
        show={
          getAllFeeStructureLoading ||
          getAllFeeStructureFetching ||
          getClassDropdownLoading
        }
      />
      <EditFeeRelaxationDialog
        getClassDropdownData={classesResponse}
        state={state}
        setState={setState}
      />
      <Drawer
        open={showPreview}
        anchor="right"
        onClose={handleClose}
        PaperProps={{
          sx: { width: "1000px" },
        }}
      >
        <PreviewForExistStudent
          allValues={mainFormik.values}
          setShowPreview={setShowPreview}
          session={selectedSession}
          requestDocId={requestDocId}
          feeData={feeData}
          collectedDocumentList={collectedDocumentList}
          state={state}
        />
      </Drawer>
      {getStudentByRequestIdLoading ? (
        <Stack direction="row" justifyContent="center" sx={{ py: 3 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <Wrapper>
          <form onSubmit={mainFormik.handleSubmit}>
            <div className="student_detail__container">
              <img
                className="student_detail__img"
                alt="student-img"
                src={
                  data?.profilePic
                    ? data?.profilePic
                    : "/image-placeholder.jpeg"
                }
              />

              <Typography
                fontSize={18}
                color="primary"
                fontWeight={600}
                sx={{ my: 1 }}
              >
                Personal Information
              </Typography>
              <div className="student_personal__detail">
                <Stack direction="row" sx={{ mb: 1 }}>
                  <NameComponent
                    head="Full Name"
                    data={`${data?.firstName} ${data?.middleName} ${data?.lastName}`}
                  />
                  <NameComponent head="Gender" data={data?.gender} />
                  <NameComponent head="Addhar Number" data={data?.AADHAR} />
                  <NameComponent
                    head="Date Of Birth"
                    data={moment(data?.DOB).format("DD-MM-YYYY")}
                  />
                  <NameComponent head="Email ID" data={data?.email} />
                  <NameComponent
                    head="Mobile Number"
                    data={data?.mobileNumber}
                  />
                </Stack>
                <Stack direction="row">
                  <NameComponent head="Community" data={data?.community} />
                  <NameComponent head="Category" data={data?.category} />
                  <NameComponent
                    head="Special Category"
                    data={data?.specialCategory}
                  />
                  <NameComponent head="Disability" data={data?.disability} />
                  <NameComponent head="Blood Group" data={data?.bloodGroup} />
                  <Box sx={{ flex: 1 }} />
                </Stack>
              </div>
              {/* parent info */}
              <Typography
                fontSize={18}
                color="primary"
                fontWeight={600}
                sx={{ mt: 2, mb: 1 }}
              >
                Parent Information
              </Typography>
              <div className="student_personal__detail">
                <Stack direction="row" sx={{ mb: 1 }}>
                  <NameComponent
                    head="Mother's Name"
                    data={`${data?.motherInfo?.firstName} ${data?.motherInfo?.middleName} ${data?.motherInfo?.lastName}`}
                  />
                  <NameComponent
                    head="Email Id"
                    data={data?.motherInfo?.email}
                  />
                  <NameComponent
                    head="Mobile Number"
                    data={data?.motherInfo?.mobileNumber}
                  />
                  <NameComponent
                    head="Occupation"
                    data={data?.motherInfo?.occupation}
                  />
                </Stack>
                <Stack direction="row" sx={{ mb: 1 }}>
                  <NameComponent
                    head="Father's Name"
                    data={`${data?.fatherInfo?.firstName} ${data?.fatherInfo?.middleName} ${data?.fatherInfo?.lastName}`}
                  />
                  <NameComponent
                    head="Email Id"
                    data={data?.fatherInfo?.email}
                  />
                  <NameComponent
                    head="Mobile Number"
                    data={data?.fatherInfo?.mobileNumber}
                  />
                  <NameComponent
                    head="Occupation"
                    data={data?.fatherInfo?.occupation}
                  />
                </Stack>
                <Stack direction="row" sx={{ mb: 1 }}>
                  <NameComponent
                    head="Guardian's Name"
                    data={`${data?.guardianInfo?.firstName} ${data?.guardianInfo?.middleName} ${data?.guardianInfo?.lastName}`}
                  />
                  <NameComponent
                    head="Email Id"
                    data={data?.guardianInfo?.email}
                  />
                  <NameComponent
                    head="Mobile Number"
                    data={data?.guardianInfo?.mobileNumber}
                  />
                  <NameComponent
                    head="Occupation"
                    data={data?.guardianInfo?.occupation}
                  />
                </Stack>
              </div>
              {/* Address Info */}
              <Typography
                fontSize={18}
                color="primary"
                fontWeight={600}
                sx={{ mt: 2, mb: 1 }}
              >
                Address Information
              </Typography>
              <div className="student_personal__detail">
                <Stack direction="row" sx={{ mb: 1 }}>
                  <NameComponent
                    head="Current Address"
                    data={`${data?.currentAddress?.address}, ${data?.currentAddress?.dist}, ${data?.currentAddress?.state}, ${data?.currentAddress?.pinCode}`}
                  />
                  <NameComponent
                    head="Permanent Address"
                    data={`${data?.permanentAddress?.address}, ${data?.permanentAddress?.dist}, ${data?.permanentAddress?.state}, ${data?.permanentAddress?.pinCode}`}
                  />
                </Stack>
              </div>
              {/* Educational Info */}
              {data?.educationInfo && data?.educationInfo?.length > 0 && (
                <>
                  <Typography
                    fontSize={18}
                    color="primary"
                    fontWeight={600}
                    sx={{ mb: 2 }}
                  >
                    Education Information
                  </Typography>
                  <div className="student_personal__detail">
                    {data?.educationInfo?.map((item, ind) => (
                      <Stack direction="row" sx={{ mb: 1 }} key={ind}>
                        <NameComponent
                          head="Discription"
                          data={item?.discription}
                        />
                        <NameComponent
                          head="University Board"
                          data={item?.instituteName}
                        />
                        <NameComponent
                          head="Percentage/Grade"
                          data={item?.grades}
                        />
                        <NameComponent
                          head="Passing Year"
                          data={item?.passingYear}
                        />
                      </Stack>
                    ))}
                  </div>
                </>
              )}

              <DrawerWrapper>
                <div className="accordian-wrapper">
                  {/* Admission details */}
                  <Accordion
                    expanded={
                      expanded === "admission_info" || expanded === "all"
                    }
                    onChange={handleChange("admission_info")}
                    className="customaccordian_style"
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowForwardIosIcon
                          sx={{
                            transform:
                              expanded === "admission_info" ||
                              expanded === "all"
                                ? "rotate(270deg) !important"
                                : "rotate(0deg) !important",
                          }}
                        />
                      }
                    >
                      <Typography className="accordian_title">
                        Admission Detail
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          {mainFormik.touched.admissionDate &&
                          mainFormik.errors.admissionDate ? (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {mainFormik.errors.admissionDate}
                            </p>
                          ) : null}
                          {mainFormik.touched.admissionType &&
                          mainFormik.errors.admissionType ? (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {mainFormik.errors.admissionType}
                            </p>
                          ) : null}

                          {mainFormik.touched.classDocId &&
                          mainFormik.errors.classDocId ? (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {mainFormik.errors.classDocId}
                            </p>
                          ) : null}
                          {mainFormik.touched.section &&
                          mainFormik.errors.section ? (
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {mainFormik.errors.section}
                            </p>
                          ) : null}
                        </div>
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails className="accordiantDetail_border">
                      <Stack direction={"row"} alignItems="center" gap={2}>
                        <Box>
                          <TextFieldLabel
                            required
                            title={"Student Admission Type"}
                          />
                          <CustomSelectBox
                            native
                            displayEmpty
                            fullWidth
                            size="small"
                            name="studentAdmissionType"
                            value={mainFormik.values.studentAdmissionType}
                            handleChange={mainFormik.handleChange}
                          >
                            <MenuItem value="" disabled component="option">
                              Select
                            </MenuItem>
                            <MenuItem value="newStudent" component="option">
                              New Student
                            </MenuItem>
                            <MenuItem value="oldStudent" component="option">
                              Old Student
                            </MenuItem>
                          </CustomSelectBox>
                        </Box>
                        <Box>
                          <TextFieldLabel
                            required
                            title={"Fee Relaxation Category"}
                          />
                          <CustomTextField
                            sx={{ backgroundColor: "rgba(208, 208, 226, 0.2)" }}
                            contentEditable={false}
                            fullWidth
                            size="small"
                            value={state.feeRelaxationCategory}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <PencilSquare
                                    style={{ color: "#000", cursor: "pointer" }}
                                    onClick={() =>
                                      setState((prev) => ({
                                        ...prev,
                                        feeRelaxationCategoryDialog: true,
                                      }))
                                    }
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Stack>

                      <AdmissionDetailComponent
                        mainFormik={mainFormik}
                        classesResponse={classesResponse}
                      />
                    </AccordionDetails>
                  </Accordion>
                  {/* Transport Info */}
                  <Accordion
                    expanded={
                      expanded === "transport_info" || expanded === "all"
                    }
                    onChange={handleChange("transport_info")}
                    className="customaccordian_style"
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowForwardIosIcon
                          sx={{
                            transform:
                              expanded === "transport_info" ||
                              expanded === "all"
                                ? "rotate(270deg) !important"
                                : "rotate(0deg) !important",
                          }}
                        />
                      }
                    >
                      <Typography className="accordian_title">
                        Transportation
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordiantDetail_border">
                      <TransportationComponent
                        setSelectedStop={setSelectedStop}
                        mainFormik={mainFormik}
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* House Info */}
                  <Accordion
                    expanded={expanded === "house_info" || expanded === "all"}
                    onChange={handleChange("house_info")}
                    className="customaccordian_style"
                  >
                    <AccordionSummary
                      expandIcon={
                        <ArrowForwardIosIcon
                          sx={{
                            transform:
                              expanded === "house_info" || expanded === "all"
                                ? "rotate(270deg) !important"
                                : "rotate(0deg) !important",
                          }}
                        />
                      }
                    >
                      <Typography className="accordian_title">House</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordiantDetail_border">
                      <Typography component={"span"}>
                        <HouseComponent
                          // allValues={mainData}
                          // setAllValues={mainData}
                          mainFormik={mainFormik}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <DocumentInfo
                    collectedDocumentList={collectedDocumentList}
                    setCollectedDocumentList={setCollectedDocumentList}
                  />
                  {/* Fee Info */}
                  <FeeStructureInfo feeData={feeData} />
                </div>
              </DrawerWrapper>
            </div>
            <Stack
              direction="row"
              justifyContent="flex-end"
              sx={{
                py: 2,
                backgroundColor: "#fff",
              }}
            >
              <Button
                color="secondary"
                type="submit"
                variant="contained"
                sx={{ width: "20%" }}
                size="large"
              >
                Preview
              </Button>
            </Stack>
          </form>
        </Wrapper>
      )}
    </>
  );
};

export default EditDetails;
const DrawerWrapper = styled.div`
  .customaccordian_style {
    box-shadow: none;
    border-radius: 15px !important;
    border: 1.5px solid #b6c8d6;
    margin-top: 10px;
  }
  .accordiantDetail_border {
    border-top: 1.5px solid #b6c8d6;
  }
  .MuiAccordion-root::before {
    opacity: 0 !important;
  }
  .accordian_title {
    font-size: 16px;
    font-weight: 500;
  }
  .accordian-wrapper {
    margin-bottom: 20px;
  }
  .preview-button {
    /* margin: 5px 20px 20px 20px; */
    width: 136.03px;
    height: 50px;
    color: rgba(39, 137, 253, 1);
    border: 1px solid #2789fd;
    border-radius: 10px;
    right: 0;
    font-weight: 500;
    font-size: 16px;
  }
  .preview-btn-div {
    display: flex;
    justify-content: right;
  }
  .divider {
    background-color: #d0d0e2;
    height: 1.5px;
  }
`;
const Wrapper = styled.div`
  padding: 1rem 2rem;
  .student_detail__container {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 15px;
    padding: 1.5rem 2rem;
  }
  .student_detail__img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    object-fit: contain;
  }
  .student_personal__detail {
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
    padding: 1rem;
  }
  .student_admission__detail {
    border: 1px solid #d0d0e2;
    border-radius: 15px;
    flex: 1;
  }
  .divider {
    background-color: #d0d0e2;
    height: 1.5px;
  }
  .edit_icon__container {
    background-color: #ebf0f4;
    height: 26px;
    width: 26px;
    border-radius: 8px;
    cursor: pointer;
  }

  .container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
    justify-content: space-between;
  }
  .container-sm {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
    justify-content: space-around;
  }
  .container-element {
    display: flex;
    flex-direction: column;
  }
  .container-element p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .container-element-extra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container-element-extra p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element-extra h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .element-wrapper {
    padding: 25px;
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
  }

  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
`;
