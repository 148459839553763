import { css } from "styled-components";

export const TIME_FORMAT = "hh:mm A";
export const DB_TIME_FORMAT = "HH:mm:ss";

export const mobile = (props) => {
  return css`
    @media only screen and (max-width: 768px) {
      ${props}
    }
  `;
};
export const SIZES = {
  desktopNavbarHeight: "70px",
};
export const THEME = {
  primary: "rgba(12, 47, 73, 1)",
  secondary: "#2789FD",
  white: "#fff",
  black: "#000",
  boxShadow1: "0px 1px 2px rgba(0,0,0,0.2)",
};
export const DATE_FORMAT = {
  withMonthName: "DD, MMM YYYY",
  indianDate: "DD-MM-YYYY",
};
export const PAYMENT_MODE = [
  {
    name: "Cash Mode",
    value: "CashMode",
  },
  {
    name: "NEFT Mode",
    value: "NEFTMode",
  },
  {
    name: "Card Mode",
    value: "CardMode",
  },
  {
    name: "Cheque Mode",
    value: "ChequeMode",
  },
  {
    name: "UPI Mode",
    value: "UpiMode",
  },
];
